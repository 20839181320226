function validateNumber(event) {
    var key = window.event ? event.keyCode : event.which;

    if (event.keyCode === 8 || event.keyCode === 46
     || event.keyCode === 40 || event.keyCode === 41
     || event.keyCode === 43 || event.keyCode === 45
     || event.keyCode === 32) {
        return true;
    }
    else if ( key < 48 || key > 57 || event.ctrlKey==true && (event.keyCode == '118' || event.keyCode == '86') ) {
        return false;
    }
    else return true;
};

$(document).ready(function(){
    $('#phoneno').keypress(validateNumber);
});