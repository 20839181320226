function mail(input)
{
	 var value = input.value;
	 var message;
	 if (value== ""){
	  	message="Please fill in this field";
	 }
	 else{
		  if(!validateEmail(value)){
		   	message='Please provide a valid email address. Eg: giant@example.com';
		  }else{
		   	message = '';
		  }
	 }
	 input.setCustomValidity(message); 
   
}

function validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}
